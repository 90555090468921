
// libraries
import * as React from 'react';
import {useState} from "react";
import {navigate} from "gatsby";

// services
import ApiService from "../services/api";

// components
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/pro-solid-svg-icons";
import Button from "./button";
import BasketContext from "../contexts/basket";
import ReactTooltip from "react-tooltip";
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe
} from "@stripe/react-stripe-js";

const BallotChargeCardDetails = (props) => {
	const elementOptions = {
		style: {
			base: {
				fontSize: '16px',
				color: '#005EB8',
			}
		}
	}

	const stripe = useStripe()
	const elements = useElements()
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const api = new ApiService();

	const handleSubmit = async (event) => {
		event.preventDefault();

		if(!stripe || !elements) {
			return
		}

		setIsLoading(true)

		api.post('ballots/pay/' + props.token + '/' + props.ballot).then(async (response) => {
			const {secret} = response.data

			const stripeResponse = await stripe.confirmCardPayment(secret, {
				payment_method: {
					card: elements.getElement(CardNumberElement)
				}
			});

			if(stripeResponse.error) {
				setError(stripeResponse.error.message)
				setIsLoading(false)
			} else {
				api.post(`ballots/payment-complete/${props.token}/${props.ballot}/${stripeResponse.paymentIntent.id}`)
					.then((response ) => {
						const ballot = response.data
						if (ballot.status_id === 7) {
							navigate(`/ballots/winner-decision/${ballot.token}/${ballot.id}/accept`)
						}
						else {
							navigate('/ballots/payment-complete')
						}
					})
			}
		});
	}

	return (
		<BasketContext.Consumer>
			{value => (
				<>
					<form onSubmit={handleSubmit}>
						<ReactTooltip id="cvc" place="right" backgroundColor="#005EB8" textColor="#fff">
							<span>Last 3 digits on the back of your card (or 4 on the front if you're using American Express)</span>
						</ReactTooltip>

						<div className="input-wrap">
							<label>Card Number*</label>
							<CardNumberElement options={elementOptions} />
							{error ? (
								<label className="input-error">{error}</label>
							) : ''}
						</div>

						<div className="input-wrap">
							<label>Card expiration date*</label>
							<CardExpiryElement options={elementOptions} />
						</div>

						<div className="input-wrap">
							<label>
								CVC* <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="cvc" />
							</label>
							<CardCvcElement options={elementOptions} />
						</div>

						<Button isLoading={isLoading} colorEndeavour xsBlock>Pay {props.fee}</Button>
					</form>
				</>
			)}
		</BasketContext.Consumer>
	)
}

export default BallotChargeCardDetails