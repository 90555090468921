
// Libraries
import * as React from 'react'
import {loadStripe} from "@stripe/stripe-js"

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import DuoSection from '../../components/duoSection'
import {Elements} from "@stripe/react-stripe-js"
import BallotChargeCardDetails from "../../components/ballotChargeCardDetails"

// Context
import NotificationContext from '../../contexts/notification'
import BallotService from "../../services/ballot"
import NumberFormat from "react-number-format"

const pk = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(pk)

class BallotsPayPage extends React.Component {
	state = {
		isSubmitting: false,
		ballot: {}
	}

	static contextType = NotificationContext

	render() {
		const {token, ballot: ballotId} = this.props;
		const {ballot} = this.state;
		const fee = <NumberFormat value={ballot.fee / 100} displayType={"text"} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'£'}/>

		return <Layout className="ballots-successful nav-blue-half no-footer">
			<Seo title="Ballot success!" />
			<DuoSection BallotsPay>
				<div>
					<div className="content-wrap">
						<h1>Ballot success!</h1>
						<p>Yes! Congratulations. You have been selected as a winner.</p>
						<p>Please enter your payment details below. When you submit the form, we will debit the {fee} processing fee and email you further details on how to claim your tickets.</p>

						<Elements stripe={stripePromise}>
							<BallotChargeCardDetails token={token} ballot={ballotId} fee={fee} />
						</Elements>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}

	componentDidMount() {
		const {addNotification} = this.context

		this.getBallot()
			.catch(() => addNotification('Ballot not found', 'error'))
	}

	getBallot = async () => {
		const ballotService = new BallotService()
		const {token, ballot} = this.props

		await ballotService.payGet(token, ballot)
			.then((response) => {
				this.setState({
					ballot: response.data
				})
			})
	}
}

export default BallotsPayPage
